import { Controller } from "@hotwired/stimulus";

/**
 * type=number な input を3桁区切りで表示してくれる
 * data-controller="input-number-format"
 */
export default class extends Controller {
  initialize() {
    this.formatter = new Intl.NumberFormat("ja-JP");

    this.input = document.createElement("input", { type: "text" });
    this.input.classList = this.element.classList;
    if (this.element.dataset.testid) {
      this.input.dataset.testid = this.element.dataset.testid;
    }
    if (this.element.placeholder) {
      this.input.placeholder = this.element.placeholder;
    }
    if (this.element.disabled) {
      this.input.disabled = true;
    }
  }

  connect() {
    this.#transferId(this.element, this.input);
    this.#onValueChange();

    this.defaultType = this.element.type;
    this.element.type = "hidden";
    this.element.before(this.input);

    // hidden だと change は発火しないので監視する
    this.observer = new MutationObserver(() => {
      this.#onValueChange();
    });
    this.observer.observe(this.element, { attributeFilter: ["value"] });

    // number からフォーカスが外れたら format された text に切り替える
    this.element.addEventListener("blur", () => {
      const isEmpty = this.element.value === "";
      this.element.before(this.input);
      this.#transferId(this.element, this.input);
      this.element.type = "hidden";

      if (isEmpty) {
        // hidden に戻した後でないと反映されない
        this.element.value = "";
      }
    });
    // text にフォーカス当たったら number の方に切り替える
    this.input.addEventListener("focus", () => {
      this.#revertInput();
      this.element.focus();
    });
  }

  disconnect() {
    this.#revertInput();
    this.observer.disconnect();
  }

  // number の更新を text に反映
  #onValueChange() {
    if (this.element.value !== "") {
      this.input.value = this.formatter.format(this.element.value);
    } else {
      this.input.value = null;
    }
  }

  /**
   * from の element.id を to に移動させる
   * @param {HTMLElement} from
   * @param {HTMLElement} to
   */
  #transferId(from, to) {
    if (!from.id) return;

    to.id = from.id;
    from.removeAttribute("id");
  }

  /**
   * input 要素を元の状態に戻す
   */
  #revertInput() {
    this.#transferId(this.input, this.element);
    this.input.remove();
    this.element.type = this.defaultType;
  }
}
