import { Controller } from "@hotwired/stimulus";
import consumer from "../../channels/consumer";
import { getTwilioConnection } from "../../shared/TwilioConnection";

/**
 * アプリのフッターメニューのトークバッジの未読数を同期する。
 *
 * data-controller="talk-badge"
 */
export default class extends Controller {
  static values = { accessToken: String, conversationSids: Array };

  connect() {
    // disconnect で後片付けするための処理。
    this.disposers = [];

    // Twilio に直接接続して取得した未読数。
    this.twilioApiCount = 0;

    // TalkBadgeChannel 経由で取得した未読数。
    this.twilioChannelCount = 0;
    this.broadcastCount = 0;
    this.talkCount = 0;

    // accessToken が設定されている場合はログインしているとみなす。
    this.login = !!this.accessTokenValue;

    if (this.login) {
      const channel = consumer.subscriptions.create("TalkBadgeChannel", {
        received: this.#received,
      });
      this.disposers.push(() => consumer.subscriptions.remove(channel));

      // Twilio の未読件数を監視する。
      this.#setUpTwilio();
    }

    // MobilicoApp の準備ができてなかった場合でも、後から送る。
    document.addEventListener("MobilicoAppLoaded", this.#trySendCountToApp);
    this.disposers.push(() =>
      document.removeEventListener("MobilicoAppLoaded", this.#trySendCountToApp)
    );
  }

  disconnect() {
    this.disposers.forEach((disposer) => disposer());
    this.disposers = [];
  }

  #received = (data) => {
    if (Object.hasOwn(data, "twilio")) {
      this.twilioChannelCount = data.twilio;
    }
    if (Object.hasOwn(data, "broadcast")) {
      this.broadcastCount = data.broadcast;
    }
    if (Object.hasOwn(data, "talk")) {
      this.talkCount = data.talk;
    }

    // 未読件数を送る。
    this.#trySendCountToApp();
  };

  #count() {
    // システムメッセージ等で正しい未読数を記録できていなかったので、
    // this.twilioApiCount と this.twilioChannelCount の大きい方を使う。
    const twilioCount = Math.max(this.twilioApiCount, this.twilioChannelCount);

    return twilioCount + this.broadcastCount + this.talkCount;
  }

  #trySendCountToApp = () => {
    if (!this.login) {
      window.MobilicoApp?.updateUnreadMessagesCount?.(0, { login: false });
      return;
    }

    // 確認しやすいように画面にも出しておく。
    this.element.textContent = `max(${this.twilioApiCount}, ${
      this.twilioChannelCount
    }) + ${this.broadcastCount} + ${this.talkCount} = ${this.#count()}`;

    // アプリに未読件数を渡す。
    window.MobilicoApp?.updateUnreadMessagesCount?.(this.#count(), {
      login: true,
    });
  };

  #setUpTwilio() {
    const accessToken = this.accessTokenValue;
    const conversationSids = this.conversationSidsValue;
    if (!accessToken || !conversationSids) {
      return;
    }

    getTwilioConnection().setAccessToken(accessToken);
    getTwilioConnection().watchUnreadMessagesCount(
      this.#updateTwilioCount,
      conversationSids
    );
    this.disposers.push(() =>
      getTwilioConnection().unwatchUnreadMessagesCount(this.#updateTwilioCount)
    );
  }

  #updateTwilioCount = (count) => {
    this.twilioApiCount = count;

    this.#trySendCountToApp();
  };
}
