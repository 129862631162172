import { Controller } from "@hotwired/stimulus";

/** bootstrap の toast をすぐに表示する。
 *
 * 使用例:
 * ```
 * <div class="toast" data-controller="flash-show-on-connect">
 *  …
 * </div>
 * ```
 */
export default class extends Controller {
  connect() {
    $(this.element)
      .toast({ delay: 5000, autohide: true })
      .on("hide.bs.toast", this.#hide)
      .on("hidden.bs.toast", this.#dispose)
      .toast("show");
  }

  #hide = () => {
    // 150ms は .fade と同じ
    $(this.element).animate({ height: 0 }, 150);
  };

  #dispose = () => {
    $(this.element)
      .off("hide.bs.toast", this.#hide)
      .off("hidden.bs.toast", this.#dispose)
      .toast("dispose");
    this.element.remove();
  };
}
